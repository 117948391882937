<template>
  <div class="header-container">
    <!-- top_bg -->
    <div class="top_bg">
      <div class="margin width1480">
        <a @click="reloadPageFun()" class="logo"
          ><img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/logo.png"
        /></a>
        <ul class="pc_mu">
          <li class="cp">
            <dd>
              <a @click="indexJumpToPageName('tansuozhe', '')">科技产品</a
              ><i class="fa fa-angle-down"></i>
            </dd>
            <div class="bg bg-new-ng dis-none">
              <div class="margin width1480 hidden">
                <div class="l">
                  <a @click="indexJumpToPageName('kaituozhe', '')">
                    <ol>
                      <img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xl_cp1.png"
                      />
                    </ol>
                    <dd>开拓者系列</dd>
                  </a>
                  <a @click="indexJumpToPageName('tansuozhe', '')">
                    <ol>
                      <img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xl_cp2.png"
                      />
                    </ol>
                    <dd>探索者系列</dd>
                  </a>
                  <a @click="indexJumpToPageName('shouwangzhe', '')">
                    <ol>
                      <img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xl_cp3.png"
                      />
                    </ol>
                    <dd>守望者系列</dd>
                  </a>
                  <a @click="indexJumpToPageName('shouhuzhe', '')">
                    <ol>
                      <img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xl_cp4.png"
                      />
                    </ol>
                    <dd>守护者系列</dd>
                  </a>
                  <a @click="indexJumpToPageName('jingang', '')">
                    <ol>
                      <img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xl_cp5.png"
                      />
                    </ol>
                    <dd>金刚系列</dd>
                  </a>
                  <a @click="indexJumpToPageName('tiaozhanzhe', '')">
                    <ol>
                      <img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/images/tiaozhanzhe-small-new.png"
                      />
                    </ol>
                    <dd>挑战者系列</dd>
                  </a>
                  <a @click="indexJumpToPageName('shenxingxia', '')">
                    <ol>
                      <img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xl_cp6.png"
                      />
                    </ol>
                    <dd>神行侠系列</dd>
                  </a>
                  <a @click="indexJumpToPageName('xiaoheixia', '')">
                    <ol>
                      <img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xl_cp7.png"
                      />
                    </ol>
                    <dd>小黑侠</dd>
                  </a>

                  <a @click="indexJumpToPageName('jiqiren', '')">
                    <ol>
                      <img
                        src="https://img.cdn.wxzhida.cn/shzd-web-site/images/jqr-01.png"
                      />
                    </ol>
                    <dd>充电机器人系列</dd>
                  </a>

                </div>
              </div>
              <i
                class="i"
                style="
                  background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/cp_dh_bg.jpg);
                "
              ></i>
            </div>
          </li>
          <li class="sj">
            <dd>
              <a @click="indexJumpToPageName('tansuozhe', '')">科技产品</a
              ><i class="fa fa-angle-down"></i>
            </dd>
            <dl>
              <a @click="indexJumpToPageName('kaituozhe', '')">开拓者系列</a>
              <a @click="indexJumpToPageName('tansuozhe', '')">探索者系列</a>
              <a @click="indexJumpToPageName('shouwangzhe', '')">守望者系列</a>
              <a @click="indexJumpToPageName('shouhuzhe', '')">守护者系列</a>
              <a @click="indexJumpToPageName('jingang', '')">金刚系列</a>
              <a @click="indexJumpToPageName('tiaozhanzhe', '')">挑战者系列</a>
              <a @click="indexJumpToPageName('shenxingxia', '')">神行侠系列</a>
              <a @click="indexJumpToPageName('xiaoheixia', '')">小黑侠系列</a>
              <a @click="indexJumpToPageName('jiqiren', '')">充电机器人系列</a>
            </dl>
          </li>
          <li>
            <dd>
              <a @click="indexJumpToPageName('application', '')">智能应用平台</a
              ><i class="fa fa-angle-down"></i>
            </dd>
            <dl class="znyypt">
              <a @click="indexJumpToPageName('application', 'd1')"
                >全国家庭充电服务平台</a
              >
              <a @click="indexJumpToPageName('application', 'd2')"
                >挚达APP介绍</a
              >
            </dl>
          </li>
          <li>
            <dd>
              <a @click="indexJumpToPageName('service', '')">服务与支持</a
              ><i class="fa fa-angle-down"></i>
            </dd>
            <dl class="fwyzc">
              <a @click="indexJumpToPageName('service', 'd1')">充电服务</a>
              <a @click="indexJumpToPageName('service', 'd2')"
                >挚达安装服务优势</a
              >
              <a @click="indexJumpToPageName('service', 'd3')">安装准备</a>
              <a @click="indexJumpToPageName('service', 'd4')">严控质量</a>
              <a @click="indexJumpToPageName('service', 'd5')">产品手册下载</a>
            </dl>
          </li>
          <li>
            <dd>
              <a @click="indexJumpToPageName('partner', '')">挚友合作</a
              ><i class="fa fa-angle-down"></i>
            </dd>
            <dl class="zyhz">
              <a @click="indexJumpToPageName('partner', 'p')">安装业务加盟</a>
              <a @click="indexJumpToPageName('partner', 'p')">产品代理加盟</a>
            </dl>
          </li>
          <li>
            <dd>
              <a @click="indexJumpToPageName('about', '')">关于我们</a
              ><i class="fa fa-angle-down"></i>
            </dd>
            <dl class="gywm">
              <a @click="indexJumpToPageName('about', 'd1')">公司简介</a>
              <a @click="indexJumpToPageName('about', 'd2')">发展历史</a>
              <a @click="indexJumpToPageName('about', 'd3')">研发生产</a>
              <a @click="indexJumpToPageName('about', 'd4')">服务能力</a>
              <a @click="indexJumpToPageName('worldview')">全球化布局</a>
              <a @click="indexJumpToPageName('news', '')">新闻中心</a>
              <a @click="indexJumpToPageName('kcxfz', '')">可持续发展</a>
            </dl>
          </li>
        </ul>
        <div class="gh">
          <i class="t"></i>
          <i class="c"></i>
          <i class="f"></i>
        </div>
        <div class="yy">
          <dd>中文</dd>
        </div>
        <!--<div class="yy">
                <dd>中文版<i class="fa fa-caret-down"></i></dd>
                <dl>
                    <a href="">英文版</a>
                </dl>
            </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
import { useStore } from "vuex";
export default {
  name: "MainHeader",
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    // 跳转
    const indexJumpToPageName = (pageName, pagePostion) => {
      $(".top_bg").removeClass("selected");
      store.commit("setCurPageTarget", pagePostion);
      if('index' == proxy.$route.name){
        proxy.$U.jumpToPageName(pageName, pagePostion);
      }else{
        proxy.$U.replaceToPageName(pageName, pagePostion);
      }
    };

    const updateImgView01 = () => {
      $(".view-type-01").removeClass("dis-none");
      $(".bg-new-ng").removeClass("dis-none");
      $(".kjcp").removeClass("dis-none");
      $(".view-type-02").addClass("dis-none");
      $(".view-type-03").addClass("dis-none");
    };

    const updateImgView02 = () => {
      $(".bg-new-ng").removeClass("dis-none");
      $(".kjcp").removeClass("dis-none");
      $(".view-type-01").addClass("dis-none");
      $(".view-type-02").removeClass("dis-none");
      $(".view-type-03").addClass("dis-none");
    };

    const updateImgView03 = () => {
      $(".view-type-01").addClass("dis-none");
      $(".view-type-02").addClass("dis-none");
      $(".view-type-03").addClass("dis-none");
      $(".bg-new-ng").addClass("dis-none");
    };

    const hideCurDomFun = (hideDom) => {
      $(hideDom).addClass("dis-none");
    };

    const hoverCurDomFun = (hideDom) => {
      $(hideDom).removeClass("dis-none");
    };

    const reloadPageFun = () => {
      proxy.$router.replace("/");
    };
    onMounted(() => {
      /*手机导航同按钮点击*/
      $(function () {
        $(".gh").click(function () {
          if ($(".top_bg").hasClass("selected")) {
            $(".top_bg").removeClass("selected");
          } else {
            $(".top_bg").addClass("selected");
          }
        });
      });

      $(function () {
        var t_bg = "150vh";
        if ($(window).scrollTop() >= t_bg) {
          $("#t1").addClass("on");
        } else {
          $("#t1").removeClass("on");
        }
      });

      $(function () {
        $(".top_bg .width1480 ul.pc_mu li").click(function () {
          var liindex = $(".top_bg .width1480 ul.pc_mu li").index(this);
          if ($(this).hasClass("x")) {
            $(this).removeClass("x");
          } else {
            $(this).addClass("x").siblings().removeClass("x");
          }
        });
      });

      var t_bg = "150vh";
      if ($(window).scrollTop() >= t_bg) {
        $("#t1").addClass("on");
      } else {
        $("#t1").removeClass("on");
      }
      var liindex = $(".top_bg .width1480 ul.pc_mu li").index(this);
      if ($(this).hasClass("on")) {
        $(this).removeClass("on");
      } else {
        $(this).addClass("on").siblings().removeClass("on");
      }
    });
    return {
      indexJumpToPageName,
      reloadPageFun,
      updateImgView01,
      updateImgView03,
      updateImgView02,
      hideCurDomFun,
      hoverCurDomFun,
    };
  },
};
</script>

<style scoped>
.dis-none {
  display: none !important;
}
</style>
