import { createStore } from 'vuex'

export default createStore({
  state: {
    curPageTarget: '',
  },
  mutations: {
    setCurPageTarget(state, curPageTarget) {
      state.curPageTarget = curPageTarget
    },
  },
  actions: {},
  modules: {},
})
