import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/",
        name: "index",
        component: () => import("../views/index/index.vue"),
      },
      {
        path: "/about",
        name: "about",
        component: () => import("../views/about/index.vue"),
      },
      {
        path: "/kaituozhe",
        name: "kaituozhe",
        component: () => import("../views/kaituozhe/index.vue"),
      },
      {
        path: "/tansuozhe",
        name: "tansuozhe",
        component: () => import("../views/tansuozhe/index.vue"),
      },
      {
        path: "/shouwangzhe",
        name: "shouwangzhe",
        component: () => import("../views/shouwangzhe/index.vue"),
      },
      {
        path: "/shouhuzhe",
        name: "shouhuzhe",
        component: () => import("../views/shouhuzhe/index.vue"),
      },
      {
        path: "/jingang",
        name: "jingang",
        component: () => import("../views/jingang/index.vue"),
      },
      {
        path: "/tiaozhanzhe",
        name: "tiaozhanzhe",
        component: () => import("../views/tiaozhanzhe/index.vue"),
      },
      {
        path: "/jiqiren",
        name: "jiqiren",
        component: () => import("../views/jiqiren/index.vue"),
      },
      {
        path: "/shenxingxia",
        name: "shenxingxia",
        component: () => import("../views/shenxingxia/index.vue"),
      },
      {
        path: "/xiaoheixia",
        name: "xiaoheixia",
        component: () => import("../views/xiaoheixia/index.vue"),
      },
      {
        path: "/application",
        name: "application",
        component: () => import("../views/application/index.vue"),
      },
      {
        path: "/kcxfz",
        name: "kcxfz",
        component: () => import("../views/kcxfz/index.vue"),
      },
      {
        path: "/worldview",
        name: "worldview",
        component: () => import("../views/worldview/index.vue"),
      },
      {
        path: "/news",
        name: "news",
        component: () => import("../views/news/index.vue"),
      },
      {
        path: "/news_show",
        name: "news_show",
        component: () => import("../views/news_show/index.vue"),
      },
      {
        path: "/partner",
        name: "partner",
        component: () => import("../views/partner/index.vue"),
      },
      {
        path: "/service",
        name: "service",
        component: () => import("../views/service/index.vue"),
      },
      {
        path: "/sitedesc",
        name: "sitedesc",
        component: () => import("../views/sitedesc/index.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
