<template>
  <div class="footer-container">
    <!-- foot_bg -->
    <div class="foot_bg">
      <div class="margin width1480 hidden">
        <div class="hidden t">
          <ul class="l">
            <li>
              <dd>
                <a @click="indexJumpToPageName('tansuozhe', '')">科技产品</a>
              </dd>
              <dl>
                <a @click="indexJumpToPageName('kaituozhe', '')">开拓者系列</a>
                <a @click="indexJumpToPageName('tansuozhe', '')">探索者系列</a>
                <a @click="indexJumpToPageName('shouwangzhe', '')"
                  >守望者系列</a
                >
                <a @click="indexJumpToPageName('shouhuzhe', '')">守护者系列</a>
                <a @click="indexJumpToPageName('jingang', '')">金刚系列</a>
                <a @click="indexJumpToPageName('tiaozhanzhe', '')"
                  >挑战者系列</a
                >
                <a @click="indexJumpToPageName('shenxingxia', '')"
                  >神行侠系列</a
                >
                <a @click="indexJumpToPageName('xiaoheixia', '')">小黑侠系列</a>
              </dl>
            </li>
            <li>
              <dd>
                <a @click="indexJumpToPageName('application', '')"
                  >智能应用平台</a
                >
              </dd>
              <dl>
                <a @click="indexJumpToPageName('application', 'd1')"
                  >全国家庭充电服务平台</a
                >
                <a @click="indexJumpToPageName('application', 'd2')"
                  >挚达APP介绍</a
                >
              </dl>
            </li>
            <li>
              <dd>
                <a @click="indexJumpToPageName('service', '')">服务与支持</a>
              </dd>
              <dl>
                <a @click="indexJumpToPageName('service', 'd1')">充电服务</a>
                <a @click="indexJumpToPageName('service', 'd2')"
                  >挚达安装服务优势</a
                >
                <a @click="indexJumpToPageName('service', 'd3')">安装准备</a>
                <a @click="indexJumpToPageName('service', 'd4')">严控质量</a>
                <a @click="indexJumpToPageName('service', 'd5')"
                  >产品手册下载</a
                >
              </dl>
            </li>
            <li>
              <dd>
                <a @click="indexJumpToPageName('partner', '')">挚友合作</a>
              </dd>
              <dl>
                <a @click="indexJumpToPageName('partner', 'p')">安装业务加盟</a>
                <a @click="indexJumpToPageName('partner', 'p')">产品代理加盟</a>
              </dl>
            </li>
            <li>
              <dd><a @click="indexJumpToPageName('about', '')">关于我们</a></dd>
              <dl>
                <a @click="indexJumpToPageName('about', 'd1')">公司简介</a>
                <a @click="indexJumpToPageName('about', 'd2')">发展历史</a>
                <a @click="indexJumpToPageName('about', 'd3')">研发生产</a>
                <a @click="indexJumpToPageName('about', 'd4')">服务能力</a>
                <a @click="indexJumpToPageName('worldview')">全球化布局</a>
                <a @click="indexJumpToPageName('news')">新闻中心</a>
                <a @click="indexJumpToPageName('kcxfz', '')">可持续发展</a>
              </dl>
            </li>
          </ul>
          <div class="r">
            <div class="t">关注或联系我们</div>
            <div class="e">
              <dl class="l">
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images/ewm1.jpg"
                />
                <p>公众号</p>
              </dl>
              <dl class="r">
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images/ewm2.jpg"
                />
                <p>服务号</p>
              </dl>
            </div>
            <div class="d">咨询热线：<b>400-659-6580</b></div>
            <a class="n">联系我们</a>
          </div>
        </div>
        <div class="hidden f">
          <div class="l">
            <ol class="i">
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/logo.png"
              />

              <span @click="toShowDjPdf()" class="ppsm-text-span"
                >品牌声明</span
              >
            </ol>
            <ol>
              Copyright © 2022 上海挚达科技发展股份有限公司版权所有
              <a
                href="http://beian.miit.gov.cn/"
                class="ba m-lt-32"
                target="_blank"
                >沪ICP备14017068号-2</a
              >
              <a
                href="https://beian.mps.gov.cn/#/query/webSearch"
                class="ba m-lt-32"
                target="_blank"
                >沪公网安备31011002005393号</a
              >
            </ol>
          </div>
          <div class="r">
            <dl class="t">
              <dd>中国站<i class="fa fa-caret-down"></i></dd>
              <!-- <ol>
                <a href="">English</a>
              </ol> -->
            </dl>
            <dl class="f">
              <a @click="showOuterPageWeibo()"><i class="fa fa-weibo"></i></a>
              <a @click="showOuterPageXiaohongshu()">
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/db90fda25cb0c9541b0fe8d2624c3688216d808e.png"
                  class="xiaohongshu-icon"
                />
              </a>
              <!-- <a href=""><i class="fa fa-qq"></i></a> -->
              关注我们
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
import { useStore } from "vuex";
export default {
  name: "Footer",
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();

    // 跳转
    const indexJumpToPageName = (pageName, pagePostion) => {
      store.commit("setCurPageTarget", pagePostion);
      proxy.$U.jumpToPageName(pageName, pagePostion);
    };

    const showOuterPageWeibo = () => {
      const wbPage =
        "https://m.weibo.cn/u/6018601441?s_trans=6018601441_&s_channel=4";
      window.open(wbPage);
    };

    const showOuterPageXiaohongshu = () => {
      const wbPage =
        "https://www.xiaohongshu.com/user/profile/6168715000000000020256bd?xhsshare=WeixinSession&appuid=591e4fce82ec394bc316b791&apptime=1679470657";
      window.open(wbPage);
    };

    const toShowDjPdf = () => {
      const pdfUrl =
        "https://img.cdn.wxzhida.cn/shzd-web-site/images/%E6%8C%9A%E8%BE%BE%E5%93%81%E7%89%8C%E5%A3%B0%E6%98%8E%E3%80%90%E5%8F%AF%E7%94%A8%E4%BA%8E%E6%89%93%E5%87%BB%E9%9D%9E%E6%8E%88%E6%9D%83%E5%BA%97%E9%93%BA%E3%80%91.pdf";
      window.open(pdfUrl);
    };

    onMounted(() => {});

    return {
      indexJumpToPageName,
      showOuterPageXiaohongshu,
      showOuterPageWeibo,
      toShowDjPdf,
    };
  },
};
</script>

<style scoped>
.m-lt-32 {
  margin-left: 32px;
  display: inline-block;
}
.xiaohongshu-icon {
  width: 40px;
}

.ppsm-text-span {
  position: absolute;
  left: 140px;
  top: 3px;
  cursor: pointer;
  display: block;
  background-color: #365ff6;
  color: #ffffff;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  border: 1px solid #365ff6;
  transition: all 0.5s ease;
  font-size: 15px;
  width: 120px;
}
</style>
