import router from '../router/index'
let utils = {}

utils.jumpToPageName = function (pageName,pagePostion = '') {
  router.push({
    name: pageName,
    query:{
      pagePostion:pagePostion
    }
  })
}
utils.replaceToPageName = function (pageName,pagePostion = '') {
  router.replace({
    name: pageName,
    query:{
      pagePostion:pagePostion
    }
  })
}


const allFileObjs = {
  'jingang':'https://img.cdn.wxzhida.cn/product/files/jingang.pdf',
  'kaituozhe':'https://img.cdn.wxzhida.cn/product/files/kaituozhe.pdf',
  'shenxingxia':'https://img.cdn.wxzhida.cn/product/files/shenxingxia.pdf',
  'tansuozhe':'https://img.cdn.wxzhida.cn/product/files/tansuozhe.pdf',
  'xiaoheixia':'https://img.cdn.wxzhida.cn/product/files/xiaoheixia.pdf',
  'shouwangzhe':'https://img.cdn.wxzhida.cn/product/files/shouwangzhe.pdf',
  'shouhuzhe':'https://img.cdn.wxzhida.cn/product/files/shouhuzhe.pdf',
  'tiaozhanzhe':'https://img.cdn.wxzhida.cn/product/files/tiaozhanzhe.pdf',
};

utils.openProductFile = function (code) {
  const fileUrl = allFileObjs[code] || allFileObjs['tansuozhe'];
  window.open(fileUrl);
}

export default utils
