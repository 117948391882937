<template>
  <div class="home">
    <main-header v-if="'Web' == state.appType"></main-header>
    <main-header-h5 v-else></main-header-h5>
    <router-view></router-view>
    <main-footer></main-footer>
  </div>
</template>

<script>
import MainHeader from "../components/MainHeader.vue";
import MainHeaderH5 from "../components/MainHeaderH5.vue";
import MainFooter from "../components/MainFooter.vue";
import { onMounted, reactive } from "vue";
export default {
  name: "Home",
  components: {
    MainFooter,
    MainHeader,
    MainHeaderH5,
  },
  setup() {
    const state = reactive({
      appType: "Web",
    });

    onMounted(() => {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //安卓端
      var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //IOS端
      const innerWidth = window.innerWidth;
      state.appType = "Web";
      if (isAndroid && innerWidth < 450) {
        state.appType = "Android";
      }
      if (isIos && innerWidth < 450) {
        state.appType = "iOS";
      }
    });

    return {
      state,
    };
  },
};
</script>
